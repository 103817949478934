import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { Alert } from '@material-ui/lab';
import {
    getAllRhNamesForMetadata,
    getRhOwnershipByRhOwnId,
    validateRhOwnershipByRhAndAssetId,
} from '@actions/apiActions';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ArrowDropDown } from '@material-ui/icons';
import CommonLookupPopupWithSuggestionList from '@common/CommonLookupPopupWithSuggestionList';
import { formatTerritoryCodes } from '@actions/territories';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
    },
    collapsed: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    listItem: {
        borderBottom: '1px solid #DEDEE0',
    },
    checkboxes: {
        padding: '0 8px 0 0',
    },
    errorBanner: {
        backgroundColor: '#FFEBEE',
        marginBottom: '32px',
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    inputsSection: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'flex-start',
    },
    categorySection: {
        marginTop: '16px',
    },
    submodalSection: {
        marginTop: '32px',
    },
    iconBtn: {
        padding: '0',
        margin: '0',
        '&:hover': {
            background: 'none',
        },
    },
}));

const RegionItem = ({
    territorySelection,
    territories,
    setTerritories,
    region,
    classes,
    isDisabled,
}) => {
    const [collapsed, setCollapsed] = useState(true);

    const countSelected = () => {
        if (territories.length === 0 && Object.keys(territories).some((key) => key.includes('0')))
            return 0;
        return territorySelection.filter((territory) => {
            return (territories[region.id] || []).includes(territory.id);
        }).length;
    };

    const handleUpdateTerritories = (territoryCode) => {
        if ((territories[region.id] || []).includes(territoryCode)) {
            setTerritories({
                ...territories,
                [region.id]: (territories[region.id] || []).filter(
                    (territory) => territory !== territoryCode,
                ),
            });
        } else {
            setTerritories({
                ...territories,
                [region.id]: (territories[region.id] || []).concat(territoryCode),
            });
        }
    };

    const selectAllTerritories = (event) => {
        if (event.target.checked) {
            let allForRegion = territorySelection.map((territory) => territory.id);

            setTerritories({ ...territories, [region.id]: allForRegion });
        } else {
            setTerritories({ ...territories, [region.id]: [] });
        }
    };

    return (
        <List key={region.id} component="div" disablePadding>
            <ListItem disableGutters className={classes.listItem}>
                <Checkbox
                    className={classes.checkboxes}
                    color="primary"
                    checked={territorySelection.every((territory) =>
                        (territories[region.id] || []).includes(territory.id),
                    )}
                    indeterminate={
                        territorySelection.some((territory) =>
                            (territories[region.id] || []).includes(territory.id),
                        ) && (territories[region.id] || []).length !== territorySelection.length
                    }
                    onClick={selectAllTerritories}
                    disabled={isDisabled}
                />
                <ListItemText
                    primary={
                        <Typography variant="subtitle2">
                            {`${region.name} (${
                                territorySelection.length
                            } items, ${countSelected()} selected)`}
                        </Typography>
                    }
                />

                <IconButton
                    onClick={() => setCollapsed(!collapsed)}
                    className={collapsed ? classes.expanded : classes.collapsed}
                    aria-expanded={!collapsed}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </ListItem>

            <Grid container>
                {!collapsed &&
                    territorySelection.map((territory) => {
                        return (
                            <Grid key={territory.id} item xs={4} lg={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(territories[region.id] || []).includes(
                                                territory.id,
                                            )}
                                            onChange={() => handleUpdateTerritories(territory.id)}
                                            name={territory.name}
                                            color="primary"
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={territory.name}
                                />
                            </Grid>
                        );
                    })}
            </Grid>
        </List>
    );
};

const DatesAndClaimMethods = ({
    isEditing,
    selectedStartDate,
    handleStartDateChange,
    selectedEndDate,
    handleEndDateChange,
    errorOperations,
    dateErrMsg = '',
    isEndDateDisabled,
    isStartDateDisabled,
}) => {
    useEffect(() => {
        if (!isEditing) {
            handleStartDateChange(moment());
            handleEndDateChange(moment());
        }
    }, [isEditing]);

    return (
        <div style={{ marginBottom: '32px' }}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <Grid container spacing={4} style={{ marginTop: '16px' }}>
                    <Grid item>
                        <KeyboardDatePicker
                            label="Start Date"
                            value={selectedStartDate}
                            onChange={(date) => handleStartDateChange(date)}
                            format="DD/MM/yyyy"
                            disabled={isStartDateDisabled}
                            helperText={errorOperations.dateError.start?.error ? dateErrMsg : ''}
                            error={errorOperations.dateError.start?.error}
                            required
                        />
                    </Grid>
                    <Grid item>
                        <KeyboardDatePicker
                            label="End Date"
                            value={selectedEndDate}
                            onChange={(date) => handleEndDateChange(date)}
                            format="DD/MM/yyyy"
                            helperText={errorOperations.dateError.end?.error ? dateErrMsg : ''}
                            error={errorOperations.dateError.end?.error}
                            disabled={isEndDateDisabled}
                            required
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
};

const NewRhOwnershipModal = ({
    showModal,
    setShowModal,
    territories,
    setTerritories,
    isEditing,
    setIsEditing,
    customData,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedStartDate, handleStartDateChange] = useState(moment());
    const [selectedEndDate, handleEndDateChange] = useState(moment());
    const params = useParams();
    const [rhName, setRhName] = useState('');
    const [shrink, setShrink] = useState(false);
    const [rhLookupAnchorEl, setRhLookupAnchorEl] = useState(null);
    const [isRhLookupOpen, setIsRhLookupOpen] = useState(false);
    const [rhCreationError, setRhCreationError] = useState('');
    const [category, setCategory] = useState('original owner');
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const assetMetadataModel = useSelector((state) => state.fugaReducers.assetMetadata);
    const {
        rhOwnershipValidations: rhOwnershipValidationsModel,
        allRhNamesFromBE,
        rhOwnershipDettailsObjFromBE,
    } = assetMetadataModel;
    const { groupedTerritories, checkedTerritories } = territoriesModel;
    const [dateError, setDateError] = useState({
        start: { error: false, msg: '' },
        end: { error: false, msg: '' },
    });
    const [percentage, setPercentage] = useState('0%');
    const mappedRhNamesList = allRhNamesFromBE.map((el) => ({
        nameToBeSent: el.fullname,
        displayName: `${el.fullname} (${el.isClient ? 'Client' : 'Non-client'})`,
        subName: `${el.isClient ? 'Client' : 'Non-client'}`,
    }));

    useEffect(() => {
        if (isEditing) {
            dispatch(getRhOwnershipByRhOwnId(customData.selectedRowDataItem.rhOwnId));
        }
    }, [isEditing]);

    useEffect(() => {
        if (rhOwnershipDettailsObjFromBE?.territoryPeriod?.territoryDTO?.territoryCodes) {
            dispatch(
                formatTerritoryCodes(
                    rhOwnershipDettailsObjFromBE.territoryPeriod.territoryDTO.territoryCodes,
                ),
            );
        }
    }, [rhOwnershipDettailsObjFromBE]);

    useEffect(() => {
        if (isEditing) setTerritories(checkedTerritories);
    }, [checkedTerritories]);

    useEffect(() => {
        if (isEditing) {
            const rhName = rhOwnershipDettailsObjFromBE?.rhName;
            setRhName({
                nameToBeSent: rhName,
                displayName: `${rhName} (${
                    rhOwnershipDettailsObjFromBE?.isClient ? 'Client' : 'Non-client'
                })`,
                subName: `${rhOwnershipDettailsObjFromBE?.isClient ? 'Client' : 'Non-client'}`,
            });
            if (rhName) setShrink(true);
            setPercentage(rhOwnershipDettailsObjFromBE?.percent + '%');
            handleEndDateChange(moment(rhOwnershipDettailsObjFromBE?.territoryPeriod?.endDate));
            handleStartDateChange(moment(rhOwnershipDettailsObjFromBE?.territoryPeriod?.startDate));
            setCategory(rhOwnershipDettailsObjFromBE?.category + '');
        }
    }, [rhOwnershipDettailsObjFromBE]);

    useEffect(() => {
        if (!isEditing) {
            setTerritories({});
        }
        dispatch(changeStateByNestedKey('assetMetadata', 'rhOwnershipValidations', {}));
    }, []);

    const handleClose = () => {
        if (isEditing) setIsEditing(false);
        setShowModal(false);
        handleEndDateChange(moment());
        handleStartDateChange(moment());
        setPercentage('0%');
        setCategory('original owner');
        setRhName({});
    };

    const closeAndReloadTable = () => {
        handleClose();
        if (customData.onTableReload) {
            dispatch(customData.onTableReload.reloadCb(customData.onTableReload.params));
        }
    };

    const onCreationFailCb = (errMsg) => {
        setRhCreationError(errMsg);
    };

    const handleSave = () => {
        if (selectedStartDate && selectedEndDate && selectedStartDate > selectedEndDate) {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'End date cannot be before start date',
                    severity: 'error',
                }),
            );
        } else if (!(selectedStartDate && selectedEndDate)) {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Please Fill in all the fields',
                    severity: 'error',
                }),
            );
        } else {
            let errObj = {};
            let allTerritoriesCount = 0;
            groupedTerritories.forEach((el) => (allTerritoriesCount += el.territories.length));
            const selectedTerritories = Object.values(territories)
                .map((territoriesByRegion) => territoriesByRegion)
                .flat()
                .join();
            const dealTerritoriesContent =
                selectedTerritories.split(',').length === allTerritoriesCount
                    ? 'WW'
                    : selectedTerritories;
            setDateError(errObj);
            const objToSave = {
                startDate: selectedStartDate.format('YYYY-MM-DD'),
                endDate: selectedEndDate.format('YYYY-MM-DD'),
                territories: dealTerritoriesContent,
                percent: Number(percentage.split('%')[0]),
                category: category,
                rhName: rhName.nameToBeSent,
            };

            if (isEditing) {
                dispatch(
                    validateRhOwnershipByRhAndAssetId(
                        customData.selectedRowDataItem.rhOwnId,
                        customData.selectedRowDataItem.assetId,
                        objToSave,
                        closeAndReloadTable,
                        onCreationFailCb,
                        true, // might be rewritten to 1 call, no if, sending isEditing as param as it's value is dynamic
                    ),
                );
            } else {
                dispatch(
                    validateRhOwnershipByRhAndAssetId(
                        params.id,
                        customData.selectedRowDataItem.assetId,
                        objToSave,
                        closeAndReloadTable,
                        onCreationFailCb,
                    ),
                );
            }
        }
    };

    const setAllTerritories = () => {
        let newTerritories = {};
        groupedTerritories.map(
            (territoryGroup) =>
                (newTerritories = {
                    ...newTerritories,
                    [territoryGroup.region.id]: territoryGroup.territories.map((t) => t.id),
                }),
        );
        setTerritories(newTerritories);
    };

    const toggleWorld = () => {
        if (allSelected()) {
            setTerritories({});
        } else {
            setAllTerritories();
        }
    };

    const noneSelected = () => {
        return Object.entries(territories).every((item) => item[1].length === 0);
    };

    const allSelected = () => {
        return groupedTerritories.every((gt) => {
            const matchedTerritory = Object.entries(territories).find((region) => {
                return gt.region.id === region[0];
            });
            return matchedTerritory && matchedTerritory[1].length === gt.territories.length;
        });
    };

    const anySelected = () => {
        return (
            Object.values(territories).length > 0 &&
            Object.values(territories).some((territory) => territory.length > 0)
        );
    };

    const handlePercentChange = (evt) => {
        const percentRegex = /^[0-9]*\.?[0-9]+%$/;
        const percentValue = evt.currentTarget.value;
        if (percentRegex.test(percentValue) || percentValue === '%') setPercentage(percentValue);
    };

    const handleRadioChange = (event) => {
        setCategory(event.target.value);
    };

    const handleRhLookupOpen = ({ currentTarget }) => {
        setRhLookupAnchorEl(currentTarget);
        setIsRhLookupOpen(true);
        dispatch(getAllRhNamesForMetadata());
    };

    const onRhNameLookupConfirm = (submittedElementFromLookup) => {
        setRhName(submittedElementFromLookup);
        setShrink(true);
    };

    const isAlertVisible = () => {
        const validationErrors =
            'valid' in rhOwnershipValidationsModel && !rhOwnershipValidationsModel.valid;
        return validationErrors || rhCreationError;
    };

    const fieldsMapping = {
        rhName: 'Rights holder name',
        ownType: 'Category (contract)',
        percent: 'Percent',
        endDate: 'End date',
        startDate: 'Start date',
    };

    return (
        <div>
            <Dialog onClose={handleClose} open={showModal} fullWidth maxWidth="sm">
                <DialogTitle onClose={handleClose}>
                    <Typography variant="subtitle1">Rights holder details</Typography>
                </DialogTitle>
                <DialogContent>
                    {isAlertVisible() && (
                        <Alert className={classes.errorBanner} variant="outlined" severity="error">
                            {Object.entries(rhOwnershipValidationsModel.errors || []).map((err) => {
                                const [errKey, errMsg] = err;
                                return (
                                    <div>
                                        {`${fieldsMapping[errKey]}: "${errMsg}"`}
                                        <br />
                                    </div>
                                );
                            })}
                            <div>{rhCreationError}</div>
                        </Alert>
                    )}
                    <div className={classes.inputsSection}>
                        <TextFieldWrapper
                            label="Rights holder name"
                            name="rhName"
                            type="text"
                            // Add shrink in case label overlaps the value
                            InputLabelProps={{ shrink: shrink }}
                            value={rhName.displayName}
                            style={{ margin: '0 32px 32px 0', minWidth: '383px' }}
                            required
                            helperText={
                                ('errors' in rhOwnershipValidationsModel &&
                                    rhOwnershipValidationsModel?.errors['rh-name']) ||
                                ''
                            }
                            error={
                                'errors' in rhOwnershipValidationsModel &&
                                rhOwnershipValidationsModel?.errors['rh-name']
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            className={`${classes.iconBtn}`}
                                            disableRipple
                                            onClick={(e) => handleRhLookupOpen(e)}
                                            // disabled={isEditing}
                                        >
                                            <ArrowDropDown style={{ marginRight: '0' }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextFieldWrapper
                            label="%"
                            onChange={(evt) => handlePercentChange(evt)}
                            name="percent"
                            type="text"
                            value={percentage}
                            style={{ margin: '0 0 32px 0', minWidth: '87px' }}
                            required
                            helperText={
                                ('errors' in rhOwnershipValidationsModel &&
                                    rhOwnershipValidationsModel?.errors['percent']) ||
                                ''
                            }
                            error={
                                'errors' in rhOwnershipValidationsModel &&
                                rhOwnershipValidationsModel?.errors['percent']
                            }
                        />
                    </div>
                    <DatesAndClaimMethods
                        selectedStartDate={selectedStartDate}
                        handleStartDateChange={handleStartDateChange}
                        selectedEndDate={selectedEndDate}
                        handleEndDateChange={handleEndDateChange}
                        isEditing={isEditing} // TODO do we need this?
                        errorOperations={{
                            dateError,
                            setDateError,
                        }}
                    />
                    <div className={classes.categorySection}>
                        <Typography variant="caption">Category (contract)</Typography>
                        <RadioGroup
                            value={category}
                            onChange={handleRadioChange}
                            row
                            style={{ marginTop: '4px' }}
                        >
                            <FormControlLabel
                                value="original owner"
                                control={
                                    <Radio
                                        size="small"
                                        color="primary"
                                        // disabled={isEditing}
                                    />
                                }
                                label={<Typography variant="body2">Original owner</Typography>}
                            />
                            <FormControlLabel
                                value="successor in title"
                                control={
                                    <Radio
                                        size="small"
                                        color="primary"
                                        // disabled={isEditing}
                                    />
                                }
                                label={<Typography variant="body2">Successor in title</Typography>}
                            />
                            <FormControlLabel
                                value="exclusive licensee"
                                control={
                                    <Radio
                                        size="small"
                                        color="primary"
                                        // disabled={isEditing}
                                    />
                                }
                                label={<Typography variant="body2">Exclusive Licensee</Typography>}
                            />
                        </RadioGroup>
                    </div>
                    <div className={classes.submodalSection}>
                        <Typography variant="subtitle1" style={{ marginBottom: '27.17px' }}>
                            Territories
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allSelected()}
                                    onChange={() => toggleWorld()}
                                    name="World"
                                    color="primary"
                                    indeterminate={allSelected() ? false : anySelected()}
                                />
                            }
                            label="World"
                        />
                        {groupedTerritories.map((territoryGroup) => (
                            <RegionItem
                                key={territoryGroup.region.id}
                                territorySelection={territoryGroup.territories}
                                territories={territories}
                                setTerritories={setTerritories}
                                region={territoryGroup.region}
                                classes={classes}
                            />
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={noneSelected()}
                        variant="contained"
                        onClick={handleSave}
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <CommonLookupPopupWithSuggestionList
                isOpen={isRhLookupOpen}
                anchorEl={rhLookupAnchorEl}
                closeCb={setIsRhLookupOpen}
                setAnchorCb={setRhLookupAnchorEl}
                itemsList={mappedRhNamesList}
                onConfirmCb={onRhNameLookupConfirm}
            />
        </div>
    );
};

export default NewRhOwnershipModal;
