import { TextField, makeStyles } from '@material-ui/core';
import React from 'react';

const useClasses = makeStyles({
    customReadOnlyTextFields: {
        '& .MuiInput-underline': {
            pointerEvents: 'none',
        },
    },
});

export default function TextFieldWrapper(props) {
    const { classNameOpt, isDisabled, ...textFieldProps } = props;
    const classes = useClasses();
    const clasStr = `${textFieldProps.className} ${classNameOpt} ${isDisabled ? classes.customReadOnlyTextFields : ''}`;
    return <TextField {...textFieldProps} className={clasStr} />;
}
