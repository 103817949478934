import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import Select from '@material-ui/core/Select';
import { AssetMetaDataArtistsSection } from '@common/AssetMetaDataEdit/AssetMetaDataArtistsSection';
import {
    createProductAPI,
    editProductAPI,
    getProductByProductIdAPI,
    validateProductAPI,
} from '@api/metadataController';
import { RecordLabelAutocomplete } from '@common/AssetMetaDataEdit/RecordLabelAutocomplete';
import { getAssetMetadataById } from '@actions/apiActions';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { validationSchema } from '@utils/validations/productValidations';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useWatch } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
    },
    collapsed: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    listItem: {
        borderBottom: '1px solid #DEDEE0',
    },
    errorBanner: {
        backgroundColor: '#FFEBEE',
        marginBottom: '32px',
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    warningBanner: {
        backgroundColor: '#FFF3E0',
        color: '#EA4B04',
        border: '1px solid #FFB44C',
        marginBottom: '32px',
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    categorySection: {
        marginTop: '16px',
    },
    submodalSection: {
        marginTop: '32px',
    },
    iconBtn: {
        padding: '0',
        margin: '0',
        '&:hover': {
            background: 'none',
        },
    },
    inputsRow: {
        display: 'flex',
        alignItems: 'end',
        marginBottom: '32px',
        '& > *': {
            marginRight: '32px',
        },
    },
    actionBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '18px',
        height: '18px',
        color: '#5F5F60',
    },
    mb2: {
        marginBottom: theme.spacing(2),
    },
    mb4: {
        marginBottom: theme.spacing(4),
    },
}));

const defaultFormValues = {
    title: '',
    upc: '',
    catalogueNumber: '',
    releaseDate: moment(),
    recordLabel: '',
    scppLabelId: null,
    displayArtist: '',
    formatDetails: '',
    compilation: null,
    volumeNumber: 0,
    trackNumber: 0,
    artists: [],
    pyear: null,
    pname: '',
    cyear: null,
    cname: '',
};

export function NewProductModal({ handleClose, showModal, dataItem, editMode, selectedProduct }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [recordLabelModalOpen, setRecordLabelModalOpen] = useState(false);
    const [yearPickerOpen, setYearPickerOpen] = useState(false);
    const [yearPickerField, setYearPickerField] = useState('');

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        getValues,
        reset,
        register,
    } = useForm({
        defaultValues: defaultFormValues,
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    // Register the 'artists' field
    useEffect(() => {
        register('artists');
    }, [register]);

    // Use 'useWatch' to observe 'artists'
    const artists = useWatch({
        control,
        name: 'artists',
    });

    const getProductData = async () => {
        const productData = await getProductByProductIdAPI(
            dataItem.assetId,
            selectedProduct.productId,
        );
        reset({
            ...defaultFormValues,
            ...productData.data,
            releaseDate: productData.data.releaseDate ? moment(productData.data.releaseDate) : null,
        });
    };

    useEffect(() => {
        if (editMode && selectedProduct) {
            getProductData();
        } else {
            reset(defaultFormValues);
        }
    }, [editMode, selectedProduct]);

    const handleYearChange = (date) => {
        const year = date.format('YYYY');
        setValue(yearPickerField, year);
        setYearPickerOpen(false);
    };

    const handleRecordLabelModalClose = () => {
        setRecordLabelModalOpen(false);
    };

    const onSubmit = async (data) => {
        const payload = {
            ...data,
            releaseDate: format(new Date(data.releaseDate), 'yyyy-MM-dd'),
        };

        const response = await validateProductAPI(payload);

        if (response.data.valid) {
            if (editMode) {
                await editProductAPI(dataItem.assetId, selectedProduct.productId, payload);
            } else {
                await createProductAPI(dataItem.assetId, payload);
            }
            dispatch(getAssetMetadataById(dataItem.assetId));
            handleClose();
        }
    };

    return (
        <Dialog onClose={handleClose} open={showModal} fullWidth maxWidth="sm">
            <DialogTitle onClose={handleClose}>
                <Typography variant="subtitle1">Product details</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    {/* Title Field */}
                    <Grid item xs={12}>
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <TextFieldWrapper
                                    {...field}
                                    label="Title"
                                    fullWidth
                                    required
                                    error={!!errors.title}
                                    helperText={errors.title ? errors.title.message : ''}
                                />
                            )}
                        />
                    </Grid>

                    {/* UPC and Catalog Number Fields */}
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name="upc"
                            control={control}
                            render={({ field }) => (
                                <TextFieldWrapper
                                    {...field}
                                    label="UPC/EAN/Barcode"
                                    fullWidth
                                    required
                                    error={!!errors.upc}
                                    helperText={errors.upc ? errors.upc.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Controller
                            name="catalogueNumber"
                            control={control}
                            render={({ field }) => (
                                <TextFieldWrapper
                                    {...field}
                                    label="Catalog number"
                                    fullWidth
                                    required
                                    error={!!errors.catalogueNumber}
                                    helperText={
                                        errors.catalogueNumber ? errors.catalogueNumber.message : ''
                                    }
                                />
                            )}
                        />
                    </Grid>

                    {/* Release Date Field */}
                    <Grid item xs={12} sm={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Controller
                                name="releaseDate"
                                control={control}
                                render={({ field }) => (
                                    <KeyboardDatePicker
                                        {...field}
                                        label="Release date"
                                        format="DD/MM/yyyy"
                                        fullWidth
                                        value={field.value || null}
                                        onChange={(date) => field.onChange(date)}
                                        error={!!errors.releaseDate}
                                        helperText={
                                            errors.releaseDate ? errors.releaseDate.message : ''
                                        }
                                    />
                                )}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    {/* Record Label and SCPP Label ID Fields */}
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} fullWidth required>
                            <Controller
                                name="recordLabel"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Record label"
                                        onClick={(event) =>
                                            setRecordLabelModalOpen(event.currentTarget)
                                        }
                                        readOnly
                                        error={!!errors.recordLabel}
                                        helperText={
                                            errors.recordLabel ? errors.recordLabel.message : ''
                                        }
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="scppLabelId"
                            control={control}
                            render={({ field }) => (
                                <TextFieldWrapper
                                    {...field}
                                    label="SCPP label ID"
                                    fullWidth
                                    required
                                    error={!!errors.scppLabelId}
                                    helperText={
                                        errors.scppLabelId ? errors.scppLabelId.message : ''
                                    }
                                />
                            )}
                        />
                    </Grid>

                    {/* Display Artist Field */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Artists</Typography>
                        <Grid item xs={12} className={classes.mb4}>
                            <Controller
                                name="displayArtist"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldWrapper
                                        {...field}
                                        label="Display artist(s)"
                                        fullWidth
                                        required
                                        error={!!errors.displayArtist}
                                        helperText={
                                            errors.displayArtist ? errors.displayArtist.message : ''
                                        }
                                    />
                                )}
                            />
                        </Grid>

                        {/* Artists Section */}
                        <AssetMetaDataArtistsSection
                            dataItem={dataItem}
                            tableReloadOnSuccess={editMode ? getProductData : () => {}}
                            productMode
                            artists={artists}
                            setArtists={(artists) => setValue('artists', artists)}
                        />
                        {errors.artists && (
                            <Typography color="error" variant="caption">
                                {errors.artists.message}
                            </Typography>
                        )}
                    </Grid>

                    {/* Format Details */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" className={classes.mb2}>
                            Format Details
                        </Typography>
                        <FormControl component="fieldset" error={!!errors.formatDetails}>
                            <FormLabel component="legend">Type</FormLabel>
                            <Controller
                                name="formatDetails"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row className={classes.mb2}>
                                        <FormControlLabel
                                            value="Album"
                                            control={<Radio size="small" color="primary" />}
                                            label="Album"
                                        />
                                        <FormControlLabel
                                            value="EP"
                                            control={<Radio size="small" color="primary" />}
                                            label="EP"
                                        />
                                        <FormControlLabel
                                            value="Single"
                                            control={<Radio size="small" color="primary" />}
                                            label="Single"
                                        />
                                        <FormControlLabel
                                            value="Box-set"
                                            control={<Radio size="small" color="primary" />}
                                            label="Box-set"
                                        />
                                        <FormControlLabel
                                            value="Music video"
                                            control={<Radio size="small" color="primary" />}
                                            label="Music video"
                                        />
                                    </RadioGroup>
                                )}
                            />
                            {errors.formatDetails && (
                                <Typography color="error" variant="caption">
                                    {errors.formatDetails.message}
                                </Typography>
                            )}
                        </FormControl>

                        <FormControl component="fieldset">
                            <FormLabel component="legend">Compilation</FormLabel>
                            <Controller
                                name="compilation"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        className={classes.mb2}
                                        onChange={(e) => field.onChange(e.target.value === 'true')}
                                    >
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio size="small" color="primary" />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio size="small" color="primary" />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </FormControl>
                    </Grid>

                    {/* Recording Details */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" className={classes.mb2}>
                            Recording Details
                        </Typography>
                        <Grid item container direction="row" spacing={2}>
                            {/* Volume Number */}
                            <Grid item xs={4}>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                    required
                                    error={!!errors.volumeNumber}
                                >
                                    <InputLabel id="volume">Volume</InputLabel>
                                    <Controller
                                        name="volumeNumber"
                                        control={control}
                                        render={({ field }) => (
                                            <Select {...field} labelId="volume">
                                                {Array.from({ length: 99 }, (_, i) => (
                                                    <MenuItem key={i + 1} value={i + 1}>
                                                        {i + 1}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {errors.volumeNumber && (
                                        <Typography color="error" variant="caption">
                                            {errors.volumeNumber.message}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>

                            {/* Track Number */}
                            <Grid item xs={4}>
                                <FormControl
                                    className={classes.formControl}
                                    fullWidth
                                    required
                                    error={!!errors.trackNumber}
                                >
                                    <InputLabel id="trackNumber">Track Number</InputLabel>
                                    <Controller
                                        name="trackNumber"
                                        control={control}
                                        render={({ field }) => (
                                            <Select {...field} labelId="trackNumber">
                                                {Array.from({ length: 99 }, (_, i) => (
                                                    <MenuItem key={i + 1} value={i + 1}>
                                                        {i + 1}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {errors.trackNumber && (
                                        <Typography color="error" variant="caption">
                                            {errors.trackNumber.message}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Copyrights */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Copyrights</Typography>
                        <Grid container spacing={2}>
                            {/* PYear and PName */}
                            <Grid item xs={6}>
                                <Controller
                                    name="pyear"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldWrapper
                                            {...field}
                                            label="Product copyright year (PYear)"
                                            fullWidth
                                            onClick={() => {
                                                setYearPickerField('pyear');
                                                setYearPickerOpen(true);
                                            }}
                                            readOnly
                                            required
                                            error={!!errors.pyear}
                                            helperText={errors.pyear ? errors.pyear.message : ''}
                                            InputLabelProps={{
                                                shrink: Boolean(field.value),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    name="pname"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldWrapper
                                            {...field}
                                            label="Product copyright text (PName)"
                                            fullWidth
                                            required
                                            error={!!errors.pname}
                                            helperText={errors.pname ? errors.pname.message : ''}
                                        />
                                    )}
                                />
                            </Grid>

                            {/* CYear and CName */}
                            <Grid item xs={6}>
                                <Controller
                                    name="cyear"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldWrapper
                                            {...field}
                                            label="Product copyright year (CYear)"
                                            fullWidth
                                            onClick={() => {
                                                setYearPickerField('cyear');
                                                setYearPickerOpen(true);
                                            }}
                                            readOnly
                                            error={!!errors.cyear}
                                            helperText={errors.cyear ? errors.cyear.message : ''}
                                            InputLabelProps={{
                                                shrink: Boolean(field.value),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    name="cname"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldWrapper
                                            {...field}
                                            label="Product copyright text (CName)"
                                            fullWidth
                                            error={!!errors.cname}
                                            helperText={errors.cname ? errors.cname.message : ''}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="text" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    color="primary"
                    disabled={!isValid}
                >
                    Save
                </Button>
            </DialogActions>

            {/* Year Picker */}
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    open={yearPickerOpen}
                    onClose={() => setYearPickerOpen(false)}
                    views={['year']}
                    value={getValues(yearPickerField) || null}
                    onChange={handleYearChange}
                    style={{ display: 'none' }}
                />
            </MuiPickersUtilsProvider>

            {/* Record Label Modal */}
            {recordLabelModalOpen && (
                <RecordLabelAutocomplete
                    open={recordLabelModalOpen}
                    onClose={handleRecordLabelModalClose}
                    anchorEl={recordLabelModalOpen}
                    setRecordLabel={(recordLabel) => {
                        setValue('recordLabel', recordLabel);
                    }}
                    setScppLabelId={(scppLabelId) => setValue('scppLabelId', scppLabelId)}
                />
            )}
        </Dialog>
    );
}
