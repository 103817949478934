import React, { useCallback, useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    makeStyles,
    Typography,
    RadioGroup,
    Radio,
    InputAdornment,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeStateByKey, changeStateByNestedKey } from '@actions/fugaActions';
import { Alert } from '@material-ui/lab';
import { getContribution, validateContributions } from '@actions/apiActions';
import TextFieldWrapper from '@MuiWrappers/TextFieldWrapper';
import { ArrowDropDown, DeleteOutlined } from '@material-ui/icons';
import CommonLookupPopupWithSuggestionList from '@common/CommonLookupPopupWithSuggestionList';
import CommonTableGrid from '@common/CommonTableGrid';
import CommonSimpleSuggestionsMenuList from '@common/CommonSimpleSuggestionsMenuList';
import OverflowTip from '@common/OverflowTip';
import CommonConfirmationDialog from '@common/CommonConfirmationDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
    },
    collapsed: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    listItem: {
        borderBottom: '1px solid #DEDEE0',
    },
    errorBanner: {
        backgroundColor: '#FFEBEE',
        marginBottom: '32px',
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    warningBanner: {
        backgroundColor: '#FFF3E0',
        color: '#EA4B04',
        border: '1px solid #FFB44C',
        marginBottom: '32px',
        '& .MuiAlert-message': {
            textAlign: 'left',
        },
    },
    categorySection: {
        marginTop: '16px',
    },
    submodalSection: {
        marginTop: '32px',
    },
    iconBtn: {
        padding: '0',
        margin: '0',
        '&:hover': {
            background: 'none',
        },
    },
    inputsRow: {
        display: 'flex',
        alignItems: 'end',
        marginBottom: '32px',
        '& > *': {
            marginRight: '32px',
        },
    },
    actionBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
        width: '18px',
        height: '18px',
        color: '#5F5F60',
    },
}));

const DatesAndClaimMethods = ({
    isEditing,
    selectedDateOfBirth,
    handleDateOfBirthChange,
    errorOperations,
    dateErrMsg = '',
}) => {
    return (
        <div>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <Grid container spacing={4} style={{ marginTop: '16px' }}>
                    <Grid item>
                        <KeyboardDatePicker
                            label="Date of birth"
                            value={selectedDateOfBirth}
                            onChange={(date) => handleDateOfBirthChange(date)}
                            format="DD/MM/yyyy"
                            disabled={isEditing}
                            helperText={errorOperations.dateError.start?.error ? dateErrMsg : ''}
                            error={errorOperations.dateError.start?.error}
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
};

const headers = [
    {
        title: 'Instrument/Role',
        dataIndex: 'instrumentsAndRoles',
    },
    {
        title: '',
        dataIndex: '',
        hasIcon: true,
    },
];

const NewContributorModal = ({ showModal, setShowModal, isEditing, setIsEditing, customData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isClient, setIsClient] = useState(false);
    const [isReselectableClient, setIsReselectableClient] = useState(false);
    const [selectedDateOfBirth, handleDateOfBirth] = useState(moment());
    const [rolesAndInstruments, setRolesAndInstruments] = useState([]);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [shrink, setShrink] = useState(false);
    const [nationalityAnchorEl, setNationalityAnchorEl] = useState(null);
    const [isNationalityLookupOpen, setIsNationalityLookupOpen] = useState(false);
    const [performanceCountryAnchorEl, setPerformanceCountryAnchorEl] = useState(null);
    const [isPerformanceCountryLookupOpen, setIsPerformanceCountryLookupOpen] = useState(false);
    const [rhCreationError, setRhCreationError] = useState('');
    const [category, setCategory] = useState('CONTRACTED_FEATURED');
    const [mainDetailsInptValues, setMainDetailsInptValues] = useState({});
    const [isRoleAndInstrumenModalOpen, setIsRoleAndInstrumenModalOpen] = useState(false);
    const [roleAndInstrumenModalAnchorEl, setRoleAndInstrumenModalAnchorEl] = useState(null);
    const [isFullNameModalOpen, setIsFullNameModalOpen] = useState(false);
    const [fullNameModalAnchorEl, setFullNameModalAnchorEl] = useState(null);
    const [selectedInstrumentAndRoleDataItem, setSelectedInstrumentAndRoleDataItem] =
        useState(null); // null and not empty obj in order to make differentiate edit/noedit mode
    const territoriesModel = useSelector((state) => state.fugaReducers.territories);
    const assetMetadataModel = useSelector((state) => state.fugaReducers.assetMetadata);
    const {
        contributionValidations: contributionValidationsModel,
        allRolesAndInstrumentsFromBE,
        contributorDettailsObjFromBE,
        allPerformerNamesFromBE,
    } = assetMetadataModel;
    const { groupedTerritories } = territoriesModel;
    const [dateError, setDateError] = useState({
        start: { error: false, msg: '' },
        end: { error: false, msg: '' },
    });
    const mappedRolesAndInstrumentssList = allRolesAndInstrumentsFromBE.map((el) => ({
        nameToBeSent: el,
        displayName: el,
    }));

    const mappedPerformersNamesList = allPerformerNamesFromBE.map((el) => ({
        nameToBeSent: el.fullName,
        displayName: `${el.fullName} (${el.isClient ? 'Client' : 'Non-client'})`,
        subName: `${el.isClient ? 'Client' : 'Non-client'}`,
        nameToBeSentReadonly: el.fullName,
        dataItemFromBE: el,
    }));

    const mapedTerritoriesForSimpleMenuList = groupedTerritories
        .map((regionGroup) => {
            return regionGroup.territories.map((countryObj) => ({
                nameToBeSent: countryObj.code,
                displayName: countryObj.name,
            }));
        })
        .flat();

    useEffect(() => {
        dispatch(changeStateByNestedKey('assetMetadata', 'contributionValidations', {}));
    }, []);

    const getFullNameValueObj = (value, isClient) => {
        return {
            nameToBeSent: value,
            displayName: value ? `${value} (${isClient ? 'Client' : 'Non-client'})` : '',
            subName: value ? `${isClient ? 'Client' : 'Non-client'}` : '',
            nameToBeSentReadonly: value,
        };
    };

    const getCountryDisplayName = (shortValue) => {
        return (
            mapedTerritoriesForSimpleMenuList.find((country) => country.nameToBeSent === shortValue)
                ?.displayName || ''
        );
    };

    useEffect(() => {
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            fullName: getFullNameValueObj(
                contributorDettailsObjFromBE.fullName,
                contributorDettailsObjFromBE.client,
            ),
            firstName: contributorDettailsObjFromBE.firstName,
            surname: contributorDettailsObjFromBE.lastName,
            ipn: contributorDettailsObjFromBE.ipn || '',
            dob: '',
            nationality: {
                displayName: getCountryDisplayName(contributorDettailsObjFromBE.nationality),
                nameToBeSent: contributorDettailsObjFromBE.nationality,
            },
            category: '',
            nrpId: contributorDettailsObjFromBE.nrpId || 0,
            performanceCountry: {
                displayName: getCountryDisplayName(contributorDettailsObjFromBE.performanceCountry),
                nameToBeSent: contributorDettailsObjFromBE.performanceCountry,
            },
            evidence: contributorDettailsObjFromBE.evidence || '',
            instrumentRoles: [],
            ...(isEditing && { currentPerformerId: customData.selectedRowDataItem.performerId }),
        });
        if (isEditing && Object.keys(contributorDettailsObjFromBE).length > 0) {
            handleDateOfBirth(moment(contributorDettailsObjFromBE.dob));
            setCategory(contributorDettailsObjFromBE?.category || 'CONTRACTED_FEATURED');
            if (contributorDettailsObjFromBE.fullName) {
                setShrink(true);
            }
            const instAndRoles = (contributorDettailsObjFromBE.instrumentRoles || []).map(
                (el) => el.instrRole,
            );
            setRolesAndInstruments([...rolesAndInstruments, ...instAndRoles]);
            setIsClient(contributorDettailsObjFromBE?.client);
        }
    }, [contributorDettailsObjFromBE]);

    useEffect(() => {
        if (isEditing) {
            dispatch(
                getContribution(
                    customData.selectedRowDataItem.performerId,
                    customData.selectedRowDataItem.assetId,
                ),
            );
        }
    }, [isEditing]);

    const handleInputChange = (event) => {
        const value = event.currentTarget.value;
        const name = event.target.name;
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            [name]: value,
        });
    };

    const handleClose = () => {
        dispatch(changeStateByNestedKey('assetMetadata', 'contributorDettailsObjFromBE', {}));
        if (isEditing) setIsEditing(false);
        handleDateOfBirth(moment());
        setCategory('CONTRACTED_FEATURED');
        setMainDetailsInptValues({});
        setRolesAndInstruments([]);
        setShowModal(false);
    };

    const closeAndReloadTable = () => {
        handleClose();
        if (customData.onTableReload) {
            dispatch(customData.onTableReload.reloadCb(customData.onTableReload.params));
        }
    };

    const onCreationFailCb = (errMsg) => {
        setRhCreationError(errMsg);
    };

    const handleSave = () => {
        if (!selectedDateOfBirth) {
            dispatch(
                changeStateByKey('infoMessage', {
                    isVisible: true,
                    message: 'Please Fill in all the fields',
                    severity: 'error',
                }),
            );
        } else {
            let errObj = {};
            setDateError(errObj);
            const objToSave = {
                ...mainDetailsInptValues,
                dob: selectedDateOfBirth.format('YYYY-MM-DD'),
                category: category,
                nrpId: Number(mainDetailsInptValues.nrpId),
                nationality: mainDetailsInptValues.nationality.nameToBeSent,
                performanceCountry: mainDetailsInptValues.performanceCountry.nameToBeSent,
                instrumentRoles: rolesAndInstruments,
                fullName: mainDetailsInptValues.fullName.nameToBeSent,
                ...(isEditing && {
                    previousPerformerId: customData.selectedRowDataItem.performerId,
                }),
            };
            if (isEditing) {
                dispatch(
                    validateContributions(
                        customData.selectedRowDataItem.assetId,
                        {
                            ...objToSave,
                        },
                        closeAndReloadTable,
                        onCreationFailCb,
                        true,
                    ),
                );
            } else {
                dispatch(
                    validateContributions(
                        customData.selectedRowDataItem.assetId,
                        objToSave,
                        closeAndReloadTable,
                        onCreationFailCb,
                    ),
                );
            }
        }
    };

    const noneSelected = () => {
        return (
            mainDetailsInptValues.firstName &&
            mainDetailsInptValues.surname &&
            mainDetailsInptValues.fullName?.nameToBeSent
        );
    };

    const handleFullNameLookupOpen = ({ currentTarget }) => {
        setFullNameModalAnchorEl(currentTarget);
        setIsFullNameModalOpen(true);
    };

    const handleRadioChange = (event) => {
        setCategory(event.target.value);
    };

    const handleTerritoriesMenuListOpen = ({ currentTarget }) => {
        setNationalityAnchorEl(currentTarget);
        setIsNationalityLookupOpen(true);
    };

    const handlePerformanceCountryMenuListOpen = ({ currentTarget }) => {
        setPerformanceCountryAnchorEl(currentTarget);
        setIsPerformanceCountryLookupOpen(true);
    };

    const onRoleAndInstrumentLookupConfirm = (
        submittedElementFromLookup,
        { isEditing = false, selectedRowValue = '' },
    ) => {
        const selectedIndexGlobal = rolesAndInstruments.findIndex(
            (el) => el === submittedElementFromLookup.nameToBeSent,
        );
        if (isEditing) {
            const selectedIndex = rolesAndInstruments.findIndex(
                (el) => el === selectedRowValue.instrumentsAndRoles,
            );
            if (selectedIndex !== -1 && selectedIndexGlobal === -1) {
                const localRolesAndInsruments = [...rolesAndInstruments];
                localRolesAndInsruments[selectedIndex] = submittedElementFromLookup.nameToBeSent;
                setRolesAndInstruments([...localRolesAndInsruments]);
            }
        } else {
            if (selectedIndexGlobal === -1) {
                setRolesAndInstruments([
                    ...rolesAndInstruments,
                    submittedElementFromLookup.nameToBeSent,
                ]);
            }
        }
    };

    const onFullNameLookupConfirm = (value) => {
        const valueDuplicate = { ...value };
        const { dataItemFromBE } = valueDuplicate;
        if (dataItemFromBE.isClient) {
            setIsReselectableClient(true);
            setIsClient(true);
        }
        valueDuplicate.nameToBeSentReadonly = value.nameToBeSent;
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            fullName: valueDuplicate,
            firstName: dataItemFromBE?.firstName || '',
            surname: dataItemFromBE?.surName || '',
            ipn: dataItemFromBE?.ipn || '',
            evidence: dataItemFromBE?.evidence || '',
            nationality: {
                displayName: getCountryDisplayName(dataItemFromBE?.nationality),
                nameToBeSent: dataItemFromBE?.nationality || '',
            },
            // null here is BE requirement
            ...(isEditing && {
                currentPerformerId: value.addedAsNew ? null : dataItemFromBE.performerId,
            }),
        });
        if (dataItemFromBE?.dob) {
            handleDateOfBirth(moment(dataItemFromBE.dob));
        } else {
            handleDateOfBirth(moment());
        }
        setShrink(true);
    };

    const isAlertVisible = () => {
        const validationErrors =
            'valid' in contributionValidationsModel && !contributionValidationsModel.valid;
        const creationErrors = rhCreationError;
        return validationErrors || creationErrors;
    };

    const isFullnameWarningVisible = () => {
        const fullNameValue = mainDetailsInptValues?.fullName?.nameToBeSentReadonly || '';
        return fullNameValue.split(' ').length > 2 && !isClient;
    };

    const openRoleAndInstrumentModal = ({ currentTarget }) => {
        setIsRoleAndInstrumenModalOpen(true);
        setRoleAndInstrumenModalAnchorEl(currentTarget);
    };

    const handleNationalityChange = (value) => {
        handleInputChange({
            // simulating html element options to use same method for value change
            currentTarget: { value: value },
            target: { name: 'nationality' },
        });
    };

    const handlePerformanceCountryChange = (value) => {
        handleInputChange({
            currentTarget: { value: value },
            target: { name: 'performanceCountry' },
        });
    };

    const handleDeleteInstrumentOrRole = (selectedRowValue) => {
        const selectedIndex = rolesAndInstruments.findIndex(
            (el) => el === selectedRowValue.instrumentsAndRoles,
        );
        if (selectedIndex !== -1) {
            const localRolesAndInsruments = [...rolesAndInstruments];
            localRolesAndInsruments.splice(selectedIndex, 1);
            setRolesAndInstruments([...localRolesAndInsruments]);
        }
    };

    const handleNameChange = (evt) => {
        const {
            currentTarget: { value: currentTypedInValue },
            target: { name: keyName },
        } = evt;
        let fullNameInProgress = '';
        if (keyName === 'firstName') {
            fullNameInProgress = `${currentTypedInValue} ${mainDetailsInptValues.surname}`;
        } else if (keyName === 'surname') {
            fullNameInProgress = `${mainDetailsInptValues.firstName} ${currentTypedInValue}`;
        }
        const fullNameObj = { ...mainDetailsInptValues.fullName };
        setMainDetailsInptValues({
            ...mainDetailsInptValues,
            [keyName]: currentTypedInValue,
            fullName: {
                ...fullNameObj,
                displayName: fullNameInProgress,
                nameToBeSent: fullNameInProgress,
            },
        });
    };

    const fieldsMapping = {
        fullName: 'Full name',
        firstName: 'First name',
        surname: 'Surname',
        ipn: 'IPN',
        dob: 'Date of birth',
        nationality: 'Nationality',
        category: 'Category',
        nrpId: 'NRP ID',
        performanceCountry: 'Country of performance',
        evidence: 'Evidence',
        instrumentRoles: 'Instruments or role',
        genericNames: 'General errors',
    };

    const getSpecificDataForInstrumentsAndRoles = useCallback((headerItem, dataItem) => {
        const coulumnTitle = headerItem.title.toLowerCase();
        if (coulumnTitle === '') {
            const handleEditClick = ({ currentTarget }) => {
                setSelectedInstrumentAndRoleDataItem(dataItem);
                setIsRoleAndInstrumenModalOpen(true);
                setRoleAndInstrumenModalAnchorEl(currentTarget);
            };
            const handleDeleteClick = () => {
                setSelectedInstrumentAndRoleDataItem(dataItem);
                setIsDeleteDialogOpen(true);
            };

            return (
                <div style={{ display: 'flex' }}>
                    <div onClick={handleEditClick}>
                        <ArrowDropDown
                            className={classes.actionBtn}
                            style={{ marginRight: '7px' }}
                        />
                    </div>
                    <div onClick={handleDeleteClick}>
                        <DeleteOutlined className={classes.actionBtn} />
                    </div>
                </div>
            );
        } else {
            return <OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>;
        }
    });

    return (
        <div>
            <Dialog onClose={handleClose} open={showModal} fullWidth maxWidth="sm">
                <DialogTitle onClose={handleClose}>
                    <Typography variant="subtitle1">Contributor details</Typography>
                </DialogTitle>
                <DialogContent>
                    {isAlertVisible() && (
                        <Alert className={classes.errorBanner} variant="outlined" severity="error">
                            {Object.entries(contributionValidationsModel.errors || []).map(
                                (err) => {
                                    const [errKey, errMsg] = err;
                                    return (
                                        <div>
                                            {`${fieldsMapping[errKey] || 'General'}: "${errMsg}"`}
                                            <br />
                                        </div>
                                    );
                                },
                            )}
                            <div>{rhCreationError}</div>
                        </Alert>
                    )}
                    {isFullnameWarningVisible() && (
                        <Alert
                            className={classes.warningBanner}
                            variant="outlined"
                            severity="warning"
                        >
                            <div>
                                {
                                    "This client has a middle name, please enter it manually in the first name field if you'd like to save it."
                                }
                            </div>
                            <div>{`Initial full name is: ${
                                mainDetailsInptValues?.fullName?.nameToBeSentReadonly || ''
                            }`}</div>
                        </Alert>
                    )}
                    <div className={classes.inputsRow}>
                        <TextFieldWrapper
                            label="Full name"
                            name="fullName"
                            type="text"
                            // Add shrink in case label overlaps the value
                            InputLabelProps={{ shrink: shrink }}
                            value={mainDetailsInptValues.fullName?.displayName || []}
                            style={{ margin: '0', width: '100%' }}
                            required
                            helperText={
                                ('errors' in contributionValidationsModel &&
                                    contributionValidationsModel?.errors['fullName']) ||
                                ''
                            }
                            error={
                                'errors' in contributionValidationsModel &&
                                contributionValidationsModel?.errors['fullName']
                            }
                            isDisabled={isClient && !isReselectableClient}
                            InputProps={{
                                readOnly: isClient && !isReselectableClient,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            className={`${classes.iconBtn}`}
                                            disableRipple
                                            onClick={(e) => handleFullNameLookupOpen(e)}
                                            // disabled={isEditing}
                                        >
                                            <ArrowDropDown style={{ marginRight: '0' }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className={classes.inputsRow}>
                        <TextFieldWrapper
                            label="First name"
                            onChange={(evt) => {
                                if (isEditing) handleNameChange(evt);
                                else handleInputChange(evt);
                            }}
                            name="firstName"
                            type="text"
                            value={mainDetailsInptValues.firstName || ''}
                            style={{ width: '100%' }}
                            required
                            isDisabled={isClient}
                            InputProps={{
                                readOnly: isClient,
                            }}
                            helperText={
                                ('errors' in contributionValidationsModel &&
                                    contributionValidationsModel?.errors['firstName']) ||
                                ''
                            }
                            error={
                                'errors' in contributionValidationsModel &&
                                contributionValidationsModel?.errors['firstName']
                            }
                        />
                        <TextFieldWrapper
                            label="Surname"
                            onChange={(evt) => {
                                if (isEditing) handleNameChange(evt);
                                else handleInputChange(evt);
                            }}
                            name="surname"
                            type="text"
                            value={mainDetailsInptValues.surname || ''}
                            style={{ width: '100%', margin: 0 }}
                            required
                            isDisabled={isClient}
                            InputProps={{
                                readOnly: isClient,
                            }}
                            helperText={
                                ('errors' in contributionValidationsModel &&
                                    contributionValidationsModel?.errors['surname']) ||
                                ''
                            }
                            error={
                                'errors' in contributionValidationsModel &&
                                contributionValidationsModel?.errors['surname']
                            }
                        />
                    </div>
                    <div className={classes.inputsRow}>
                        <TextFieldWrapper
                            label="IPN"
                            onChange={(evt) => handleInputChange(evt)}
                            name="ipn"
                            type="text"
                            value={mainDetailsInptValues.ipn || ''}
                            isDisabled={isClient}
                            InputProps={{
                                readOnly: isClient,
                            }}
                            helperText={
                                ('errors' in contributionValidationsModel &&
                                    contributionValidationsModel?.errors['ipn']) ||
                                ''
                            }
                            error={
                                'errors' in contributionValidationsModel &&
                                contributionValidationsModel?.errors['ipn']
                            }
                        />
                        <DatesAndClaimMethods
                            selectedDateOfBirth={selectedDateOfBirth}
                            handleDateOfBirthChange={handleDateOfBirth}
                            // selectedEndDate={selectedEndDate}
                            // handleEndDateChange={handleEndDateChange}
                            isEditing={isClient}
                            errorOperations={{
                                dateError,
                                setDateError,
                            }}
                        />
                        <TextFieldWrapper
                            label="Nationality"
                            name="nationality"
                            type="text"
                            value={mainDetailsInptValues.nationality?.displayName || ''}
                            helperText={
                                ('errors' in contributionValidationsModel &&
                                    contributionValidationsModel?.errors['nationality']) ||
                                ''
                            }
                            error={
                                'errors' in contributionValidationsModel &&
                                contributionValidationsModel?.errors['nationality']
                            }
                            isDisabled={isClient}
                            InputProps={{
                                readOnly: isClient,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            className={`${classes.iconBtn}`}
                                            disableRipple
                                            onClick={(e) => handleTerritoriesMenuListOpen(e)}
                                            // disabled={isEditing}
                                        >
                                            <ArrowDropDown style={{ marginRight: '0' }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className={classes.submodalSection}>
                        <Typography variant="subtitle1" style={{ marginBottom: '27.17px' }}>
                            Contribution to asset
                        </Typography>
                        <div className={classes.inputsRow}>
                            <TextFieldWrapper
                                label="NRP ID"
                                onChange={(evt) => handleInputChange(evt)}
                                name="nrpId"
                                type="text"
                                value={mainDetailsInptValues.nrpId || ''}
                                // style={{ margin: '0 0 32px 0' }}
                                helperText={
                                    ('errors' in contributionValidationsModel &&
                                        contributionValidationsModel?.errors['nrpId']) ||
                                    ''
                                }
                                error={
                                    'errors' in contributionValidationsModel &&
                                    contributionValidationsModel?.errors['nrpId']
                                }
                            />
                            <TextFieldWrapper
                                label="Country of performance"
                                onChange={(evt) => handleInputChange(evt)}
                                name="performanceCountry"
                                type="text"
                                value={mainDetailsInptValues.performanceCountry?.displayName || ''}
                                style={{ minWidth: '200px' }}
                                disableTouchRipple
                                helperText={
                                    ('errors' in contributionValidationsModel &&
                                        contributionValidationsModel?.errors[
                                            'performanceCountry'
                                        ]) ||
                                    ''
                                }
                                error={
                                    'errors' in contributionValidationsModel &&
                                    contributionValidationsModel?.errors['performanceCountry']
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={`${classes.iconBtn}`}
                                                disableRipple
                                                onClick={(e) =>
                                                    handlePerformanceCountryMenuListOpen(e)
                                                }
                                                // disabled={isEditing}
                                            >
                                                <ArrowDropDown style={{ marginRight: '0' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className={classes.inputsRow}>
                            <TextFieldWrapper
                                label="Evidence"
                                onChange={(evt) => handleInputChange(evt)}
                                name="evidence"
                                type="text"
                                value={mainDetailsInptValues.evidence || ''}
                                style={{ margin: 0, width: '100%' }}
                                helperText={
                                    ('errors' in contributionValidationsModel &&
                                        contributionValidationsModel?.errors['evidence']) ||
                                    ''
                                }
                                error={
                                    'errors' in contributionValidationsModel &&
                                    contributionValidationsModel?.errors['evidence']
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.categorySection}>
                        <Typography variant="caption">Category (contract)</Typography>
                        <RadioGroup
                            value={category}
                            onChange={handleRadioChange}
                            row
                            style={{ marginTop: '4px' }}
                        >
                            <FormControlLabel
                                value="CONTRACTED_FEATURED"
                                control={
                                    <Radio
                                        size="small"
                                        color="primary"
                                        // disabled={isEditing}
                                    />
                                }
                                label={<Typography variant="body2">Contracted Featured</Typography>}
                            />
                            <FormControlLabel
                                value="NON_FEATURED"
                                control={
                                    <Radio
                                        size="small"
                                        color="primary"
                                        // disabled={isEditing}
                                    />
                                }
                                label={<Typography variant="body2">Non Featured</Typography>}
                            />
                            <FormControlLabel
                                value="OTHER_FEATURED"
                                control={
                                    <Radio
                                        size="small"
                                        color="primary"
                                        // disabled={isEditing}
                                    />
                                }
                                label={<Typography variant="body2">Other Featured</Typography>}
                            />
                        </RadioGroup>
                    </div>
                    <div className={classes.submodalSection}>
                        <Typography variant="subtitle1" style={{ marginBottom: '27.17px' }}>
                            Instrument or role
                        </Typography>
                        <CommonTableGrid
                            dataList={{
                                content: rolesAndInstruments.map((instrument) => ({
                                    instrumentsAndRoles: instrument,
                                })),
                            }}
                            headerList={headers}
                            cellDataCb={getSpecificDataForInstrumentsAndRoles}
                            preferences={{
                                loadDataByPageCb: {
                                    // no need to pass params here
                                },
                            }}
                            selection={{
                                isSelectable: false,
                            }}
                            isInSubModal={true}
                            rowsPerPageOff={true}
                            tablePaginationOff={true}
                            footerAddition={{
                                itemComponent: (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        disableRipple
                                        onClick={openRoleAndInstrumentModal}
                                    >
                                        Add Instrument or role
                                        <ArrowDropDown style={{ marginRight: '0' }} />
                                    </Button>
                                ),
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={!noneSelected()}
                        variant="contained"
                        onClick={handleSave}
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <CommonSimpleSuggestionsMenuList
                isOpen={isNationalityLookupOpen}
                closeCb={setIsNationalityLookupOpen}
                onConfirmCb={handleNationalityChange}
                anchorEl={nationalityAnchorEl}
                setAnchorCb={setNationalityAnchorEl}
                menuItems={mapedTerritoriesForSimpleMenuList}
            />
            <CommonSimpleSuggestionsMenuList
                isOpen={isPerformanceCountryLookupOpen}
                closeCb={setIsPerformanceCountryLookupOpen}
                onConfirmCb={handlePerformanceCountryChange}
                anchorEl={performanceCountryAnchorEl}
                setAnchorCb={setPerformanceCountryAnchorEl}
                menuItems={mapedTerritoriesForSimpleMenuList}
            />
            <CommonLookupPopupWithSuggestionList
                isOpen={isRoleAndInstrumenModalOpen}
                anchorEl={roleAndInstrumenModalAnchorEl}
                closeCb={setIsRoleAndInstrumenModalOpen}
                setAnchorCb={setRoleAndInstrumenModalAnchorEl}
                itemsList={mappedRolesAndInstrumentssList}
                onConfirmCb={onRoleAndInstrumentLookupConfirm}
                searchPlaceholder="Search instrument or role"
                displayOptions={{
                    noSubName: true,
                    noOptionBtns: true,
                    openUp: true,
                    initialSuggestionsOn: true,
                }}
                selectedItemOptions={{
                    selectedDataItem: selectedInstrumentAndRoleDataItem,
                    setSelectedDataItem: setSelectedInstrumentAndRoleDataItem,
                }}
            />
            <CommonLookupPopupWithSuggestionList
                isOpen={isFullNameModalOpen}
                anchorEl={fullNameModalAnchorEl}
                closeCb={setIsFullNameModalOpen}
                setAnchorCb={setFullNameModalAnchorEl}
                itemsList={mappedPerformersNamesList}
                onConfirmCb={onFullNameLookupConfirm}
                searchPlaceholder="Search performer"
            />
            <CommonConfirmationDialog
                isOpen={isDeleteDialogOpen}
                closeCb={setIsDeleteDialogOpen}
                title="Confirm delete Instrument or role"
                description={`Would you like to delete '${
                    selectedInstrumentAndRoleDataItem?.instrumentsAndRoles || ''
                }' ?`}
                onConfirmCb={handleDeleteInstrumentOrRole}
                confirmCbArgs={[selectedInstrumentAndRoleDataItem]}
                custumConfirmText="Delete"
            />
        </div>
    );
};

export default NewContributorModal;
